import React, { useEffect, useState } from "react";
import { FaEye, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from "framer-motion";

const MIN_AREA = 20;
const MAX_AREA = 200;

const ApartmentsList = () => {
    const [filters, setFilters] = useState({
        id: "",
        floor: "",
        areaRange: [MIN_AREA, MAX_AREA],
        exposure: "",
        rooms: "",
        price: "",
        status: "",
    });

    const [data, setData] = useState([]);
    const [selectedApartment, setSelectedApartment] = useState(null);

    useEffect(() => {
        const rowData = [{
            id: "M01",
            rooms: 1,
            area: "54 m²",
            orientation: "Południe",
            floor: 2,
            price: "810,000 zł",
            status: "Dostępne",
            floorPlanUrl: "https://paweljarczak.pl/wp-content/uploads/2020/08/rzut-mieszkania-z-wymiarami.png", // Add URL for floor plan
            actions: {
                view: "Zobacz",
                download: "PDF"
            }
        },
        {
            id: "M02",
            rooms: 3,
            area: "65 m²",
            orientation: "Południe",
            floor: 3,
            price: "975,000 zł",
            status: "Dostępne",
            floorPlanUrl: "https://paweljarczak.pl/wp-content/uploads/2020/08/rzut-mieszkania-z-wymiarami.png", // Add URL for floor plan
            actions: {
                view: "Zobacz",
                download: "PDF"
            }
        },
        {
            id: "M03",
            rooms: 4,
            area: "72 m²",
            orientation: "Południe",
            floor: 3,
            price: "1 080,000 zł",
            status: "Rezerwacja",
            floorPlanUrl: "https://paweljarczak.pl/wp-content/uploads/2020/08/rzut-mieszkania-z-wymiarami.png", // Add URL for floor plan
            actions: {
                view: "Zobacz",
                download: "PDF"
            }
        },
        {
            id: "M04",
            rooms: 2,
            area: "45 m²",
            orientation: "Południe",
            floor: 1,
            price: "675,000 zł",
            status: "Sprzedane",
            floorPlanUrl: "https://paweljarczak.pl/wp-content/uploads/2020/08/rzut-mieszkania-z-wymiarami.png", // Add URL for floor plan
            actions: {
                view: "Zobacz",
                download: "PDF"
            }
        }];
        setData(rowData);
    }, []);

    const handleViewClick = (apartment) => {
        setSelectedApartment(apartment);
    };

    const handleClosePopup = () => {
        setSelectedApartment(null); // Close popup
    };

    return (
        <section className="pb-24 px-5">
            <div className="container mx-auto grid grid-cols-12 gap-8">
                {/* Header */}
                <div className="col-span-12">
                    <div className="text-center max-w-2xl mx-auto pb-12">
                        <h2 className="font-montserrat text-6xl font-normal py-4 text-gray-800">
                            Lokale
                        </h2>
                    </div>
                </div>

                {/* Apartments Table */}
                <div className="col-span-12 lg:col-span-12">
                    <div className="overflow-x-auto bg-white shadow-lg rounded-lg border border-gray-200">
                        <table className="w-full text-gray-600 text-center">
                            <thead className="bg-mainColor text-white">
                                <tr>
                                    {["Mieszkanie", "Kondygnacja", "Powierzchnia", "Pokoje", "Cena", "Status", "Karta mieszkania"].map((header) => (
                                        <th key={header} className="p-4 font-semibold text-sm sm:text-base uppercase tracking-wide font-poppins">{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {data?.map((val, key) => (
                                    <tr key={key} className="hover:bg-gray-50 transition-all">
                                        <td className="p-3 border-b font-montserrat">{val.id}</td>
                                        <td className="p-3 border-b font-montserrat">{val.floor}</td>
                                        <td className="p-3 border-b font-montserrat">{val.area}</td>
                                        <td className="p-3 border-b font-montserrat">{val.rooms}</td>
                                        <td className="p-3 border-b font-montserrat">{val.price}</td>
                                        <td className={`p-3 border-b font-semibold font-montserrat ${val.status === "Sprzedane"
                                            ? "text-red-600"
                                            : val.status === "Rezerwacja"
                                                ? "text-yellow-600"
                                                : val.status === "Dostępne"
                                                    ? "text-mainColor"
                                                    : ""}`}>
                                            {val.status}
                                        </td>
                                        <td className="p-3 border-b">
                                            <button
                                                onClick={() => handleViewClick(val)}
                                                className="font-montserrat bg-mainColor/[0.8] hover:bg-mainColor/[1] text-white px-4 py-2 rounded-lg text-sm transition-all"
                                            >
                                                <div className="flex flex-row items-center gap-2">

                                                    <svg class="w-full h-full text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                        <path stroke="currentColor" stroke-width="1.5" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                                                        <path stroke="currentColor" stroke-width="1.5" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                    </svg>

                                                    {val.actions.view}
                                                </div>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Apartment Details Popup */}
            <AnimatePresence>
                {selectedApartment && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                        onClick={handleClosePopup}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.1 }}
                    >
                        <motion.div
                            className="bg-white p-6 w-full h-full flex flex-col relative"
                            onClick={(e) => e.stopPropagation()}
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            transition={{ duration: 0.1 }}
                        >
                            {/* Close Button */}
                            <button
                                onClick={handleClosePopup}
                                className="absolute top-4 right-4 text-2xl text-gray-600 hover:text-gray-900"
                            >
                                <FaTimes />
                            </button>

                            {/* Apartment Details */}
                            <div className="h-full">
                                <div className="grid grid-cols-12 h-full">
                                    <div className="col-span-12 md:col-span-4 h-full bg-slate-50">
                                        <div className="flex flex-col h-full justify-center ml-24 space-y-2">
                                            <h3 className="font-montserrat text-6xl font-normal text-gray-800">Szczegóły<br />mieszkania</h3>
                                            <p className="font-montserrat text-xl font-light text-gray-500"><strong>Mieszkanie:</strong> {selectedApartment.id}</p>
                                            <p className="font-montserrat text-xl font-light text-gray-500"><strong>Powierzchnia:</strong> {selectedApartment.area}</p>
                                            <p className="font-montserrat text-xl font-light text-gray-500"><strong>Status:</strong> {selectedApartment.status}</p>
                                            <p className="font-montserrat text-xl font-light text-gray-500"><strong>Piętro:</strong> {selectedApartment.floor}</p>
                                            <p className="font-montserrat text-xl font-light text-gray-500"><strong>Pokoje:</strong> {selectedApartment.rooms}</p>
                                            <p className="font-montserrat text-xl font-light text-gray-500"><strong>Cena:</strong> {selectedApartment.price}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-12 md:col-span-8 h-full">
                                        <div className="w-full h-full flex items-center justify-center">
                                            <img src="https://zarabiajnanieruchomosciach.pl/wp-content/uploads/2016/07/Zrzut-ekranu-2016-07-05-o-00.01.04.png" className="" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </section>
    );
};

export default ApartmentsList;
