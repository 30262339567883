import React, { useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import pinIcon from '../../assets/images/pin.png';
import SZYM_taras_001 from '../../assets/images/SZYM_taras_001.jpg';

const Localization = () => {
    // State to track active marker
    const [activeMarkerId, setActiveMarkerId] = useState(null);

    // Marker locations with custom icons
    const locations = [
        {
            id: 1,
            position: [50.01066624058447, 19.87761042647693],
            title: 'Moje Zacisze',
            description: '0 km',
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/1076/1076983.png',
        },
        {
            id: 2,
            position: [50.01709863557438, 19.87374867838555],
            title: 'Szkoła',
            description: '1.1km',
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/1076/1076983.png',
        },
        {
            id: 3,
            position: [50.01206624517394, 19.87576573753655],
            title: 'Przystanek ',
            description: '500m',
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/1076/1076983.png',
        },
        {
            id: 4,
            position: [50.01160435960555, 19.886634048285753],
            title: 'Centrum handlowe',
            description: '1.5km',
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/1076/1076983.png',
        },
        {
            id: 5,
            position: [50.01062413782758, 19.881738408723855],
            title: 'Restauracja 3 kaczki',
            description: '450m',
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/1076/1076983.png',
        },
        {
            id: 6,
            position: [50.01767979858995, 19.877881329558797],
            title: 'Przedszkole',
            description: '1km',
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/1076/1076983.png',
        },
        {
            id: 7,
            position: [50.01291019240335, 19.87600409138147],
            title: 'Żłobek',
            description: '600m',
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/1076/1076983.png',
        },
    ];

    const features = locations.map(({ id, title, description }) => ({
        id,
        title,
        description,
    }));

    // References for markers
    const markerRefs = useRef({});

    // Event Handlers
    const handleMouseEnter = (id) => {
        setActiveMarkerId(id);
        if (markerRefs.current[id]) {
            markerRefs.current[id].openPopup(); // Open popup on hover
        }
    };

    const handleMouseLeave = (id) => {
        setActiveMarkerId(null);
        if (markerRefs.current[id]) {
            markerRefs.current[id].closePopup(); // Close popup
        }
    };

    // Helper function to create custom icons dynamically
    const createCustomIcon = (iconUrl, isActive) => {
        return L.icon({
            iconUrl,
            iconSize: isActive ? [36, 36] : [32, 32],
            iconAnchor: isActive ? [18, 36] : [16, 32],
            popupAnchor: [0, -32],
        });
    };

    return (
        <section className='bg-cover md:bg-fixed'
            style={{
                backgroundImage: `url(${SZYM_taras_001})`,
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="bg-black/[0.5] py-24 px-5">
                <div className="container mx-auto px-6">
                    {/* Section Header */}
                    <div className="mx-auto max-w-5xl text-center mb-12">
                        <h2 className="drop-shadow-xl font-poppins font-thin uppercase text-3xl md:text-4xl lg:text-5xl pb-4 text-white">
                            Lokalizacja
                        </h2>
                        <p className="drop-shadow-xl font-poppins font-thin text-lg md:text-xxl lg:text-3xl text-white">
                            Osiedle znajduje się w jednej z najbardziej pożądanych lokalizacji w mieście.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                        <div className="lg:col-span-2 grid gap-4">
                            {features.map(({ id, title, description }) => (
                                <div
                                    key={id}
                                    className="bg-white px-4 py-2 cursor-pointer hover:bg-gray-100 transition-all flex flex-row items-center gap-4"
                                    onMouseEnter={() => handleMouseEnter(id)}
                                    onMouseLeave={() => handleMouseLeave(id)}
                                >
                                    <img src={pinIcon} alt="" className="h-6" />
                                    <div className="mt-2">
                                        <h2 className="text-sm font-semibold font-montserrat text-gray-900">{title}</h2>
                                        <p className="text-xs font-montserrat text-gray-500">{description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Map Section */}
                        <div className="lg:col-span-10 relative z-10">
                            <MapContainer
                                center={[50.011619918187705, 19.877485787333782]} // Map center coordinates
                                zoom={15}
                                className="w-full h-[500px] shadow-lg overflow-hidden"
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
                                />

                                {/* Map Markers */}
                                {locations.map(({ id, position, title, description, iconUrl }) => (
                                    <Marker
                                        key={id}
                                        position={position}
                                        icon={createCustomIcon(iconUrl, activeMarkerId === id)}
                                        ref={(el) => (markerRefs.current[id] = el)}
                                    >
                                        <Popup>
                                            <div className="text-sm">
                                                <strong className="text-gray-800">{title}</strong>
                                                <p className="text-gray-600 mt-1">{description}</p>
                                            </div>
                                        </Popup>
                                    </Marker>
                                ))}
                            </MapContainer>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Localization;
