import React, { useRef, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import SZYM_ogrody_001 from '../../assets/images/SZYM_ogrody_001.jpg'
import SZYM_parking_001 from '../../assets/images/SZYM_parking_001.jpg'
import SZYM_taras_001 from '../../assets/images/SZYM_taras_001.jpg'

const images = [
    SZYM_ogrody_001,
    SZYM_parking_001,
    SZYM_taras_001,
    SZYM_ogrody_001,
    SZYM_parking_001,
    SZYM_taras_001,
];

function PhotoGallery() {
    const carouselRef = useRef();
    const [width, setWidth] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [dragged, setDragged] = useState(false);
    const [calcWidth, setCalcWith] = useState(true);

    // Obliczenie szerokości do przesuwania
    useEffect(() => {
        const updateWidth = () => {
            if (carouselRef.current) {
                setWidth(
                    carouselRef.current.scrollWidth - carouselRef.current.offsetWidth
                );
            }
        };

        updateWidth(); // Wywołanie funkcji na start

        window.addEventListener("resize", updateWidth); // Aktualizacja przy zmianie rozmiaru okna

        return () => window.removeEventListener("resize", updateWidth); // Czyszczenie listenera
    }, [calcWidth]);

    // Funkcja obsługująca kliknięcie
    const handleImageClick = (src) => {
        if (!dragged) {
            setSelectedImage(src);
        }
    };

    return (
        <div className="overflow-hidden w-full pb-24 px-4">
            {/* Główny kontener karuzeli */}
            <motion.div
                ref={carouselRef}
                className="flex cursor-grab active:cursor-grabbing"
                drag="x"
                dragConstraints={{ right: 0, left: -width }}
                onDragStart={() => setDragged(true)} // Ustawienie flagi na true podczas przeciągania
                onDragEnd={() => setDragged(false)} // Resetowanie flagi po zakończeniu przeciągania
                onMouseEnter={()=>{setCalcWith(!calcWidth)}}
            >
                {images.map((src, index) => (
                    <motion.div
                        key={index}
                        className="min-w-[300px] md:min-w-[550px] mr-4"
                        onClick={() => !dragged && handleImageClick(src)} // Kliknięcie tylko jeśli nie było drag
                    >
                        <img
                            src={src}
                            alt={`Slide ${index + 1}`}
                            className="rounded-lg shadow-lg w-full h-auto pointer-events-none"
                        />
                    </motion.div>
                ))}
            </motion.div>

            {/* Powiększone zdjęcie */}
            <AnimatePresence>
                {selectedImage && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => setSelectedImage(null)} // Zamknięcie zdjęcia po kliknięciu
                    >
                        <motion.img
                            src={selectedImage}
                            alt="Enlarged"
                            className="rounded-lg shadow-2xl w-[90%] h-auto max-w-4xl"
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.5 }}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default PhotoGallery;
