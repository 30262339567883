import React from 'react'
import { FaBed, FaHome, FaTree, FaParking, FaCalendar } from 'react-icons/fa'
import SZYM_parking_001 from '../../assets/images/SZYM_parking_001.jpg'

const Features = () => {
  return (
    <div className='bg-cover md:bg-fixed mb-24'
      style={{
        backgroundImage: `url(${SZYM_parking_001})`,
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="bg-black/[0.65] py-24">
        <div className="container mx-auto">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-3 bg-transparent text-center mb-16 md:mb-0">
              <div className="aspect-square max-w-28 mx-auto mb-4 ">
                <svg class="w-full h-full text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.3" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z" />
                </svg>

              </div>
              <h3 className='text-6xl font-poppins font-extralight text-white pb-4'>2025</h3>
              <span className='text-2xl font-montserrat font-extralight text-white pb-4'>Rok budowy</span>
            </div>
            <div className="col-span-12 md:col-span-3 bg-transparent text-center mb-16 md:mb-0">
              <div className="aspect-square max-w-28 mx-auto mb-4 ">
                <svg class="w-full h-full text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.3" d="M11 6.5h2M11 18h2m-7-5v-2m12 2v-2M5 8h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1Zm0 12h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1Zm12 0h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1Zm0-12h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1Z" />
                </svg>

              </div>
              <h3 className='text-6xl font-poppins font-extralight text-white pb-4'>55-76m<sup>2</sup></h3>
              <span className='text-2xl font-montserrat font-extralight text-white pb-4'>Powierzchnia</span>
            </div>
            <div className="col-span-12 md:col-span-3 bg-transparent text-center mb-16 md:mb-0">
              <div className="aspect-square max-w-28 mx-auto mb-4 ">
                <svg class="w-full h-full text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.3" d="M12 5V3m0 18v-2M7.05 7.05 5.636 5.636m12.728 12.728L16.95 16.95M5 12H3m18 0h-2M7.05 16.95l-1.414 1.414M18.364 5.636 16.95 7.05M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
                </svg>

              </div>
              <h3 className='text-6xl font-poppins font-extralight text-white pb-4'>50-100m<sup>2</sup></h3>
              <span className='text-2xl font-montserrat font-extralight text-white pb-4'>Ogród i taras</span>
            </div>
            <div className="col-span-12 md:col-span-3 bg-transparent text-center mb-16 md:mb-0">
              <div className="aspect-square max-w-28 mx-auto mb-4 ">
                <svg class="w-full h-full text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.3" d="M18 17v2M12 5.5V10m-6 7v2m15-2v-4c0-1.6569-1.3431-3-3-3H6c-1.65685 0-3 1.3431-3 3v4h18Zm-2-7V8c0-1.65685-1.3431-3-3-3H8C6.34315 5 5 6.34315 5 8v2h14Z" />
                </svg>

              </div>
              <h3 className='text-6xl font-poppins font-extralight text-white pb-4'>2-4</h3>
              <span className='text-2xl font-montserrat font-extralight text-white pb-4'>Sypialnie</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
